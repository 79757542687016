@font-face {
    font-family: 'GothamHTF-Book';
    src: url('../fonts/GothamHTF-Book.eot');
    src: url('../fonts/GothamHTF-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamHTF-Book.woff2') format('woff2'),
        url('../fonts/GothamHTF-Book.woff') format('woff'),
        url('../fonts/GothamHTF-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GothamHTF-Bold';
    src: url('../fonts/GothamHTF-Bold.eot');
    src: url('../fonts/GothamHTF-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamHTF-Bold.woff2') format('woff2'),
        url('../fonts/GothamHTF-Bold.woff') format('woff'),
        url('../fonts/GothamHTF-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GothamHTF-Book_1';
    src: url('../fonts/GothamHTF-Book_1.eot');
    src: url('../fonts/GothamHTF-Book_1.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamHTF-Book_1.woff2') format('woff2'),
        url('../fonts/GothamHTF-Book_1.woff') format('woff'),
        url('../fonts/GothamHTF-Book_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BebasKai';
    src: url('../fonts/BebasKai.eot');
    src: url('../fonts/BebasKai.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BebasKai.woff2') format('woff2'),
        url('../fonts/BebasKai.woff') format('woff'),
        url('../fonts/BebasKai.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'GothamHTF-Medium';
    src: url('../fonts/GothamHTF-Medium.eot');
    src: url('../fonts/GothamHTF-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamHTF-Medium.woff2') format('woff2'),
        url('../fonts/GothamHTF-Medium.woff') format('woff'),
        url('../fonts/GothamHTF-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'BebasNeueBold';
    src: url('../fonts/BebasNeueBold.eot');
    src: url('../fonts/BebasNeueBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BebasNeueBold.woff2') format('woff2'),
        url('../fonts/BebasNeueBold.woff') format('woff'),
        url('../fonts/BebasNeueBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


