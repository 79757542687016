
* {box-sizing: border-box;}
ol {margin: 0}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}
p {
  strong {
    font-weight: 700;
  }
}
h1{
	font-weight: bold;
	font-size: $h1-font-size;
	line-height: normal;
	font-family: $bebasKai;
	margin-bottom: 10px;
	font-weight: 400;
}
h2{
	font-weight: 400;font-size:$h2-font-size;
	font-family: $bebasKai;
	line-height: normal;
}
h3{
	font-size: $h3-font-size;
	font-weight: 400;
	line-height: 1.20;
	letter-spacing: 1px;
	font-family: $bebasKai;
	text-transform: uppercase;
}		
h4{
	font-weight: 400;
	font-family: $bebasKai;
	font-size: $h4-font-size;
	line-height: 1.2;}
h5{
	font-weight: 500;
	font-size: $h5-font-size;
	text-transform: uppercase;
	line-height: 1;
	letter-spacing: 1px;
}
h6{
	font-size: $h6-font-size;
	font-family: $font-family-sans-serif;
	text-transform: uppercase;
}
body {
	color: #434343;
	margin: 0;
	padding: 0;
	font-size: $px20;
	font-weight: 400;
	position: relative;
	line-height: 1.5;
	font-family: $font-family-sans-serif;
}
a {color: inherit}
.container-xl ,
.container-lg ,
.container {
	max-width: 1150px;
	margin: 0 auto;
	padding: 0 15px;
	width: 100%;
}
.container-fluid{
	padding-left: 60px;
	padding-right: 60px;
}
.container-lg{
	padding: 0 15px;
	max-width: 1310px;
}
.container-xl {
	padding: 0 15px;
	max-width: 1590px;
}
.hero-section {
	background-color: $gray_bg;
	height: 82vh;
	&:before{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 280px;
		opacity: .68;
		background: rgb(255,255,255);
	background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(57,57,57,1) 100%);
	}
}
.home {
	.hero-section {
		height: 100vh;
	}
}
.img-cover {
	> img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.header{
	padding: 60px 0;
	z-index: 123;
	background-color: transparent;
	transition: all .5s;
	/*&.fixed{
		padding: 25px 0;
		background: rgb(7,24,59);
		background-image: linear-gradient(196deg, #0e2d74 0%, #07173a 100%);
	}*/
}
.without-hero {

.header{
	padding: 60px 0;
	z-index: 123;
	background: rgb(7,24,59);
	background-image: linear-gradient(196deg, #0e2d74 0%, #07173a 100%);
	transition: all .5s;
	// &.fixed{
	// 		padding: 25px 0;
	// 	}
	 }
}
.main-menu{
	font-family: $bebasKai;
	font-size: 30px;
	text-transform: uppercase;
	ul {
		color: #fff;
		grid-column-gap: 30px;
		column-gap: 30px;
		li {
			&.current-menu-item a ,
			a:hover{ 
				color: $yellow_color;
			}
		}
	}
}
.blueBg{
	background: rgb(7,24,59);
	background-image: linear-gradient(185deg, #0e2d74 0%, #07173a 100%);
	color: #fafafa;
	* {color: #fafafa;}
}
.gray-bg {
	background-color: rgba(240, 240, 240, 0.30);
}
.yellow-bg{
	background-color: $yellow_color;
	color: $blueColor;
	* {color: $blueColor}
}
.p-100 {
	padding-top: 100px;
	padding-bottom: 100px;
}
.p-120 {
	padding-top: 120px;
	padding-bottom: 120px;
}
.p-140 {
	padding-top: 140px;
	padding-bottom: 140px;
}
.p-180 {
	padding-top: 175px;
	padding-bottom: 175px;
}
.mb-100{
	margin-bottom: 100px;
}
.link-btn{
	color: $yellow_color;
	text-transform: uppercase;
	display: inline-block;
	padding-bottom: 10px;
	font-family: $bebasKai;
	line-height: 1;
	position: relative;
	letter-spacing: 0.1px;
	&:hover{
		color: $blueColor;
		&:before{
			background-color: $blueColor;
		}
	}
	&:before{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		bottom: 0;
		height: 2px;
		width: 66px;
		max-width: 78%;
		transition: all .4s;
		background-color: $yellow_color;
	}
	&.blck-btn {
		color: $primary;
		&:hover{
			opacity: .75;
			color: $primary;
		}
		&:before{
			background-color: $primary;
			width: 55px;
			right: auto;
		}
	}
}

p:last-child {margin-bottom: 0}

.p-120.gray-bg {
	padding: 100px 0 140px;
}



.logo {
	img{
		width: 180px;
		height: auto;
	}
}
//sub-menu
.sub-menu {
    position: absolute;
    top: auto;
    left: 0;
    width: 220px;
    display: none;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    z-index: 1;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    background: #ffffff;
    border-radius: 5px;
}
.main-menu ul li{position: relative;}
.main-menu ul li ul li{padding: 7px 15px;font-size: 18px;}
.main-menu ul li ul li a, .main-menu ul li.current-menu-item ul li a{color: #000000;}
.main-menu ul li ul li:hover a, .main-menu ul li.current-menu-item ul li:hover a{color: #FBD901;}
@media screen and (max-width:991px){
	.sub-menu {position: static;opacity: 1;visibility: visible;display: none;transition: none;-o-transition: none;-webkit-transition: none;-ms-transition: none;-moz-transition: none;background: none;transform: none;box-shadow: none;transition: none;}
    .main-menu ul li ul li a, .main-menu ul li.current-menu-item ul li a{color: #ffffff;} 
    .click_menu {position: absolute;  right: 20px;display: block; cursor: pointer; top: 9px; z-index: 999999;}
    .click_menu:before{content: '+';position: absolute;font-size: 24px;font-weight: 500;text-align: center;color: #ffffff;right: 0;}
    .click_menu.open2:before{content: '-';}
    .nav_bg nav .inner_nav > ul > li:hover .click_menu:before{color: #ffffff;border-color: #ffffff;}
	.main-menu ul li ul li{padding: 0px;}
}
@media screen and (min-width:992px) {
	.main-menu ul li:hover>.sub-menu{opacity: 1;visibility: visible;display: block;}
}