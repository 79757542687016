.footer {
	i {
		color: inherit
	}
	h6{
		margin-bottom: 60px;
	}
	p {
		display: flex;
		align-items: center;
		grid-gap: 8px;
		grid-gap: 8px;
		a {
		   line-height: 1;
		   &:hover{
		   	color: $yellow_color;
		   }
		}
	}
}
.footer-row{
	grid-row-gap: 55px;
	row-gap: 55px;
	.f_items_sec:not(:nth-child(odd)) {
		padding-left: 290px;
	}
}
.social-icons {
	ul{
		grid-gap: 8px;
		gap: 8px;
		li{
			a:hover{
				color: $yellow_color;
			}

		}
	}
	p{
		font-family: 'GothamHTF-Book';
		margin-bottom: 0;
	}
}
.cont-link {
	a {
		text-transform: uppercase;
		display: inline-block;
		padding-bottom: 12px;
		position: relative;
		&:hover{
			color: $yellow_color;
			&:before{
				background-color: $yellow_color;
			}
		}
		&:before{
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			height: 2px;
			width: 55px;
			background-color: #fff;
		}
	}
}