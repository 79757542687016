.about-sec {
	h3{
		margin-bottom: 45px;
	}
	h5 {
		padding-top: 15px;
		margin-bottom: 35px;
		 font-family: 'GothamHTF-Medium';
		font-weight: 500;
	}
	p {
		margin-bottom: 35px;
	}
	p:last-child {margin-bottom: 0}
}
p {
	em ,
	strong{
		font-family: $goth_bold;
	}
}
strong{
	font-family: $goth_bold;
}
.happy-client {
	h3{
		margin-bottom: 40px;
	}
	p{
		line-height: 1.25;
		font-weight: 500;
		font-family: $goth_med;
	}
	h6{
		padding-top: 40px;
		text-transform: initial;
		margin: 0;
	}
}

.result-sec{
	background-size: cover;
	color: #fafafa;
	&:before{
		content: '';
	    mix-blend-mode: multiply;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(205deg, #0e2d74 0%, #07173a 100%);
		opacity: 0.933;
	}
	h3{
		margin-bottom: 105px;
	}
}


.result-sec-item {
	width: 310px;
	height: 310px;
	font-size: 54px;
	font-family: $bebasNeueBold;
	margin-bottom: 65px;
	.resultTxt{
		&:after ,
		&:before {
			content: '';
			width: 168px;
			height: 168px;
			border: 2px solid $yellow_color;
			border-radius: 50%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50% , -50%)
		}
		&:after {
			width: 100%;
			left: 0;
			top: 0;
			transform: none;
			height: 100%;
		}
	}
	
}

.result-sec {
	p{
		font-family: $goth_bold;
		font-size: 22px;
		line-height: 1;
		margin: 0;
		span{
			font-family: $font-family-sans-serif;
			font-weight: 400;
		}
	}
}
.result-sec-item{
	&:before{
		content: '';
		height: 2px;
		background-color: $yellow_color;
		position: absolute;
		top: 50%;
		margin-top: -1px;
		width: 250px;
		left: -177px;
	}
	
}
li{
	&:first-child{
		.result-sec-item:before{
			width: 700px;
			left: -627px;
		}
	}
}
li{
	&:last-child{
		.result-sec-item:after{
			content: '';
			height: 2px;
			background-color: $yellow_color;
			position: absolute;
			top: 50%;
			margin-top: -1px;
			width: 700px;
			right: -627px;
			left: auto;
		}
	}
}
.heading{
	margin-bottom: 80px;
	h3 {margin-bottom: 18px;}
	p{
		margin: 0;
	}
}
.date-data {
	font-size: 18px;
	line-height: 1.6;
	margin-bottom: 20px;

}
.line{
	margin-bottom: 30px;
	height: 2px;
	background-color: $blueColor;
}
.news-sec{
	h5{
		margin-bottom: 25px;
	}
	p{
		margin-bottom: 20px;
		line-height: 1.1;
	}
}

.news-row{
	margin: 0 -60px;
	margin-bottom: 100px;
	grid-row-gap: 160px;
	row-gap: 160px;
	> div {
		padding: 0 60px;
	}
}
.our-team {
	h3{
		margin-bottom: 40px;
	}
}


.hotel-name {
	padding: 50px 100px;
	h5{
		color: #fff;
		font-family: $goth_bold ;
		margin: 0;
		letter-spacing: 1px;
	}
}

.featured-bottom{
	padding: 80px 100px;
}

.featured-left {
	max-width: 590px;
	width: 100%;
	p {
		margin-bottom: 80px;
	}
	*:last-child{margin-bottom: 0}
}

.thumb-item {
	max-width: 170px;
	padding: 0 12px;
	cursor: pointer;
	.thumb-img {
		width: 145px;
		height: 145px;
		border-radius: 50%;
		overflow: hidden;
		margin: 0 auto 20px;
	}
	p{
		margin: 0;
		font-size: 18px;
		line-height: 1;
		padding-bottom: 5px;
	}
}

.featured-thumb-sec{
	max-width: 860px;
	width: 100%;
}

.agent-sec{
	.co
	h3 {
		margin-bottom: 80px;
	}
	.col-agent-items-in{
		&:hover{
			img{
				opacity: 0.75;
				transition: all .4s
			}
		}
	}
}
.agent-img{
	margin-bottom: 18px;
	font-size: 0;
}
.agent-left {
	h5{
		color: $primary;
		font-family: $goth_med;
		font-weight: 500;
		line-height: 1.1;
		margin: 0;
	}
	p {
		font-size: 18px;
		color: $blueColor;
		line-height: 1.2;
	}
}
.agent-row {
	grid-row-gap: 30px;
	row-gap: 30px;
	margin: 0 -10px;
	.agent-item {
		padding: 0 10px;
	}
}
.agent-sec{
	h3{
		margin-bottom: 80px;
	}
}

.core-value{
	h3{margin-bottom: 60px;}
	h6{
		font-size: 22px;
		line-height: 1;
		font-weight: 700;
		font-family: $goth_bold;
		margin-bottom: 25px;
	}
	p{
		font-family: $goth_med;
		line-height: 1.5;
		margin: 0;
		font-size: 20px;
		+ p {
			margin-top: 25px;
		}
	}
	.container{
		max-width: 1484px;
	}
}

.core-row {
	grid-row-gap: 60px ;
	row-gap: 60px ;
	margin: 0 -25px;
	.core-item {
		padding: 0 25px;
		&:nth-child(3n+1) {
			h6{
				&:before{
					width: 800px;
					left: -820px
				}
			}
		}
		&:nth-child(3n+0) {
			h6{
				&:after{
					width: 800px;
					right: -820px
				}
			}
		}
	}
	h6{
		position: relative;
		display: inline-block;
		&:after ,
		&:before{
			content: '';
			height: 2px;
			background-color: $yellow_color;
			position: absolute;
			top: 50%;
			margin-top: -1px;
			width: 180px;
			left: -200px;
		}
		&:after {
			left: auto;
			right: -200px;
		}
	}
}





.slick-arrow {
	width: 124px;
	height: 124px;
	z-index: 3;
	left: 60px;
	top: -63px;	
	transform: none;
	background-image: url('../images/arrow-left.png') !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	&.slick-next {
		left: 220px;
		background-image: url('../images/arrow-right.png') !important;
		background-repeat: no-repeat !important;
		background-position: center !important;
	}
	&:after ,
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		font-size: 0;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: 0;
		border: 2px solid $yellow_color;
		opacity: 1 !important;
	}
	&:after {
		top: 50%;
		display: block !important;
		left: 50%;
		transform: translate(-50% , -50%);
		width: 68px;
		height: 68px;
	}
}

.slick-arrow span {
  position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 45px 45px 0 0;
	background-color: white;
	width: 63px;
	height: 36px;
	margin-top: -17px;
	z-index: -1;
}

.slick-arrow span.arrow{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	background-image: url('../images/arrow-left.png') !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	transform: none;
	height: 100%;
    background: transparent;
    margin: 0;
    border-radius: 50%;
}
.slick-arrow.slick-next span.arrow{
	background-image: url('../images/arrow-right.png') !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
}
.slider-nav {
	.slick-track {
		margin: 0 0 0 auto;
	}
	.slick-arrow {
		width: 68px;
		height: 68px;
		top: calc(50% - 25px);
		left: -80px;
		transform: translateY(-50%) ;
		&.slick-next {
			left: auto;
			right: -80px;
		}
		&:before{
			display: none;
		}
		&:after {

		}
	}
}
.happy-client {
	h3{
		position: absolute;
		left: 0;
		right: 0 ;
		margin: 0 auto;
		top: 120px;
	}
}
.story-slider{
	position: static;
	padding: 210px 0 120px;
}

.join-tem {
	p{
		font-family: $goth_med;
		font-weight: 500;
		margin: 0;
	}
	padding: 65px 0;
	.link-btn{
		color: $blueColor;
		&:before{
			right: 0;
			background-color: $blueColor;
			margin: 0 0 0 auto;
		}
	}
}



.about-data {
	font-size: 28px;
	padding: 55px 80px	;
	h5 {
		font-family: $goth_bold;
		margin: 0	;
	
	}
	ul{
		grid-gap: 34px;
		gap: 34px;
		i {transition: all .4s}
	}
	ul li a:hover i,
	a:hover{
		color: $yellow_color;
	}
}

.about-left {
	max-width: 722px;
	width: 100%;
}

.about-right{
	padding-left: 25px;
	h3{
		margin-bottom: 10px;
	}
	p{margin-bottom: 30px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	h6{
		text-transform: initial;
		color: $blueColor;
		margin-bottom: 55px;
	}
}
.img-sec{
	height: 840px;
}

.slick-slider .slick-dots {
	bottom: 45px;
  right: 80px;
  left: auto;
  width: auto;
  line-height: 1;
  li{
  	padding: 0;
  	margin: 0 0 0 20px;
  	opacity: .46;
  	width: 14px;
  	background-color: $yellow_color;
  	border-radius: 50%;
  	height: 14px;
  	button {
  		display: none !important;
  	}
  	&.slick-active {
  		opacity: 1;
  	}
  }
}

.border-top-color {
	border-top: 2px solid $yellow_color;
}

.page-item:first-child .page-link ,
.page-link {
	background-color: transparent !important;
	border: 0;
	padding: 0;
	font-size: 30px;
	color: $yellow_color;
	font-family: $bebasKai ;
	line-height: 1.4;
	padding: 0 6px;

}
.active .page-link{
	color: $blueColor;
	background-color: transparent;
}
.page-item.disabled{
	opacity: 0;
}


.our-news {
	h3{
		margin-bottom: 45px;
	}
	p{
		margin: 0;
	}
}
.news-full-sec{
	padding: 80px 0 120px;
}
.filter-opt{
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: '';
	outline: none;
	font-size: 25px;
	font-family: $bebasKai;
	color: $blueColor;
	width: 135px;
	position: relative;
	padding: 0 20px 0 10px;
	cursor: pointer;
	transition: all .4s;
}
.filter-row{
	margin-bottom: 90px;
}
.filter-wrap:hover{
	&:before, 
	 .filter-opt{
		color: $yellow_color;
	}
}
.filter-wrap {
	margin-left: 15px;
	&:before{
		transition: all .4s;
		content: '>';
		color: #000;
		font-size: 25px;
		position: absolute;
		right: 5px;
		top: 0;
			font-family: $bebasKai;
			transform: rotate(90deg)
	}
}

.news-details {
	.container {
		max-width: 1210px;
	}

	h1 {
		text-align: center;
		margin-bottom: 45px;
		font-size: 35px;
	}
	p {
		margin-bottom: 35px;
		img{
			padding: 35px 0;
		}
	}
}


.navi-share {
	padding: 0 20px;
	padding-top: 140px;
	.pagination{
		font-size: 30px;
		color: $yellow_color;
		font-family: $bebasKai;
		text-transform: uppercase;
		a {
			display: inline-block;
			padding: 0 10px;
			&:hover{
				color: #0E2D74
			}
		}
	}
	.social-icons{
		column-gap: 20px;
		grid-column-gap: 20px;
		color: #002d74;
		font-size: 24px;
		a:hover{color: #FBD901;}
		i {

		}
	}
}
.slick-arrow span.arrow {transition: all .1s}
.slick-arrow:hover span.arrow{
	background-size: 16px;
}
.service-sec:not(.service-details){
	.container {
		max-width: 100%;
		padding: 0 100px;
	}
	.link-btn{
		&:hover{
			color: #fff;
			&:before{
				background-color: #fff;
			}
		}
	}
}
.service_item {
	padding: 0 60px;
	border-right: 2px solid #FBD901;
	h4{
		font-size: 36px;
		margin-bottom: 40px;
		letter-spacing: 2px;
	}
	p{
		margin-bottom: 60px;
		line-height: 1.5;
		font-size: $px20;
		font-family: $font-family-sans-serif;
	}
	&:last-child{border: 0}
}

.service-details{
	padding: 80px 0 160px;
	h4{
		margin-bottom: 36px;
		font-size: 35px;
		letter-spacing: 2px;
	}
}
.services-list{
	grid-row-gap: 30px;
	row-gap: 30px;
	padding-top: 40px;
	li{
		padding: 0 35px;
		font-family: $goth_bold;
		font-size: 22px;
		line-height: 1;
		color: #0E2D74;
		letter-spacing: 0;
		font-size: 22px;
		+ li {
			border-left: 2px solid #FBD901;
		}
	}
}

.service_btm {
	padding-bottom: 100px;
	p{
		margin-top: 40px;
		position: relative;
		&:before{
			content: '•';
			display: inline-block;
			margin-right: 20px;
		}
	}
}
.h4-sty h4 {
	font-size: 35px;
	letter-spacing: 2px;	
}
.mt-70{
	margin-top: 100px;
}

.map-sec {
	font-size: 0;
	border-left: 2px solid #FBD901;
	padding: 0;
	.iframe ,
	iframe{
		width: 100%;
		height: 100%;
		/*filter: grayscale(1);*/
		height: 600px;
	}
}
.contact-sec {
    font-size: 25px;
    font-family: $goth_med;
    line-height: 2;
    a:hover{color: #FBD901;}
	i{
		color: #0E2D74;
	}
}
.map-contact {
	h4{
		margin-bottom: 35px;
	}
}

.form-control:focus ,
.form-control {
	border: 1px solid #fff;
	padding: 0 30px;
	background-color: transparent;
	border-radius: 0;
	height: 60px;
	box-shadow: none;
	color: #fff;
	font-family: $goth_med;
	font-size: 18px;
}
textarea.form-control:focus ,
textarea.form-control {
	height: 300px;
	padding: 15px 30px;
	
	font-family: $goth_med;
	resize:none;
	box-shadow: none;
}

.form-row {
	row-gap: 20px;
	margin-left: -10px;
	margin-right: -10px;
	> * {
		padding: 0 10px;
	}
}

.space-140 {
	padding-left: 140px;
	padding-right: 140px;
}
.form-sec {
	h4{
		margin-bottom: 90px;
	}
}

input.link-btn {
	background-color: transparent;
	border: 0;
	color: #FBD901;
	width: auto;
	padding: 10px 0;
}
.btn-wrap:before {
	content: "";
    position: absolute;
    right: 10px;
    bottom: 0;
    height: 2px;
    width: 44px;
    transition: all 0.4s;
    background-color: #FBD901;
}

.form-control::-webkit-input-placeholder { /* Edge */
  color: inherit;
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: inherit;
}

.form-control::placeholder {
  color: inherit;
}

.page-link:hover{
    color: #0E2D74;
}


.service-row{
	row-gap: 60px;
	grid-row-gap: 60px;
}



/*------------------*/
.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
}
.nice-select:hover {
  border-color: #dbdbdb;
}
.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: #999;
}
.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}
.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
    color: #0E2D74 !important;
}
.nice-select .option.selected {
  font-weight: bold;
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

.nice-select {
	background: transparent;
    border: 0;
    font-size: 25px;
    font-family: "BebasKai";
    color: #0E2D74;
}

.slick-arrow span.arrow ,
.slick-arrow.slick-prev span.arrow ,
.slick-arrow.slick-next span.arrow {
	background-size: 12px 12px;
}
.slick-arrow:hover span.arrow ,
.slick-arrow.slick-prev:hover span.arrow ,
.slick-arrow.slick-next:hover span.arrow {
	background-size: 16px 16px;
}
.slider-nav .slick-arrow, .slick-arrow.slick-next ,
.slick-arrow ,.featured-slider-section .slick-arrow {
	background-image: none !important;
}