/* START :: Custom variable */
html , body  {
    overflow-x: hidden;
}
button , a , input[type="submit"] {
  outline: none !important;
  box-shadow: none !important;
  transition: all 0.3s; text-decoration: none;color: inherit;
}
img {max-width: 100%;}

$px70 : 3.875rem;
$px64 : 4rem;
$px60: 3.75rem;
$px48 : 3rem;
$px45 : 2.813rem;
$px42 : 2.688rem;
$px40 : 2.5rem;
$px36 : 2.25rem;
$px32 : 2rem;
$px30 : 1.875rem;
$px28 : 1.75rem;
$px27 : 1.688rem;
$px26 : 1.625rem;
$px24 : 1.5rem;
$px22 : 1.375rem;
$px21 : 1.313rem;
$px20 : 1.25rem;
$px19 : 1.188rem;
$px18 : 1.125rem;
$px17 : 1.063rem;
$px16 : 1rem;
$px15 : 0.938rem;
$px14 : 0.875rem;
$px13 : 0.813rem;
$px12 : 0.75rem;
$px11 : 0.688rem;
$px10 : 0.625rem;


$body-bg : #fff;
$body-color: #393939;
$primary: #393939;
$gray_bg : #A09D99;
$yellow_color : #FBD901;
$link-color : #FBD901;
$blueColor: #0E2D74;
$link-decoration: none;
$link-hover-color: $yellow_color;
$font-size-base: $px18;
$font-size-base-mob: $px16;
$font-weight-base: 300;
$container-padding-x: 15px !default;
$line-height-base: 1.66;
$font-family-sans-serif: 'GothamHTF-Book';
$goth_med:'GothamHTF-Medium';
$goth_bold:'GothamHTF-Bold';
$bebasKai : 'BebasKai';
$bebasNeueBold : 'BebasNeueBold';
$btn-border-radius: 10px;
$btn-border-width:  0;
$btn-font-weight:  700;
$btn-padding-y: 0;
$btn-padding-x:20px;
$btn-font-family:$font-family-sans-serif;
$btn-font-size: $px18 ;
$btn-box-shadow: none;
$h1-font-size:60px;
$h2-font-size:52px;
$h3-font-size:45px;
$h4-font-size:32px;
$h5-font-size:25px;
$h6-font-size:20px;
$headings-font-family: $font-family-sans-serif;
$headings-line-height: normal;
$headings-color: #fff;;
$headings-font-weight: 300;
$box-radius : 15px;