@media(min-width : 768px) {
	.main-menu{
		display: block !important;
	}
}

@media(max-width : 1800px) {
	.space-140{
		padding-left: 110px;
		padding-right: 110px;
	}
}
@media(max-width : 1680px) {
	.space-140{
		padding-left: 70px;
		padding-right: 70px;
	}
	.contact-sec {font-size: 22px;}
	.map-sec iframe, .map-sec .iframe {height: 560px;}
	.form-sec h40 {
	    margin-bottom: 70px;
	}
	.service-sec:not(.service-details) .container {
	    padding: 0 20px;
	}
	.service_item {
		padding: 0 40px;
	}
	.news-sec .container-xl, .core-value .container, .agent-sec .container-xl{
		padding: 0 60px;
	}
	
}
@media(max-width : 1540px) {
	.footer-row .f_items_sec:not(:nth-child(odd)) {
		padding-left: 120px;
	}
	.featured-bottom {
		padding: 60px;
	}
	.slider-nav .slick-arrow.slick-next {
		right: -50px;
	}
	.slider-nav .slick-arrow.slick-prev {
		left: -60px;
	}
	.thumb-item .thumb-img {
	    width: 130px;
    	height: 130px;
	}
}
@media(max-width : 1439px) {
	.contact-sec {
	    font-size: 20px;
	}
	.space-140 {
	    padding-left: 15px;
	    padding-right: 15px;
	}
	.map-contact > .row{margin: 0}
	textarea.form-control:focus, textarea.form-control {height: 240px;}
	.service-sec:not(.service-details) .container {
	    padding: 0 15px;
	}
	.service_item p {font-size: 18px; margin-bottom: 40px;}
	.service_item h4 {font-size: 32px;}
	.service_item {
		padding: 0 30px;
	}
	.featured-thumb-sec {
	    max-width: 660px;
	}
	.slider-nav .slick-arrow.slick-next {
		right: -60px;
	}
}
@media(max-width : 1309px) {
	body {
		font-size: 19px;
	}
	.result-sec{
		.container{
			max-width: 1150px;
		}
	}
	.result-sec-item:before {
	    width: 240px;
    	left: -168px;
	}
	.p-120 {
		padding-top: 90px;
    	padding-bottom: 90px;
	}
	.p-100 {
		padding-top: 80px;
    	padding-bottom: 80px;
	}
	.p-120.gray-bg {
		padding: 80px 0 110px;
	}
	.p-140 {
		padding-top: 110px;
		padding-bottom: 110px;
	}
	.news-row {
		margin: 0 -20px 90px;
		> div {
			padding: 0 20px;
		}
	}
	.container-fluid, .news-sec .container-xl, .core-value .container, .agent-sec .container-xl{
		padding-left: 30px;
		padding-right: 30px;
	}
	h3{font-size: 40px;}
	h5{font-size: 24px;}
	.footer h6 {
		font-size: 18px;
		margin-bottom: 40px;
	}
	.featured-bottom {
		padding: 50px 30px;
	}
	.featured-thumb-sec {margin-right: 35px !important;}
	.hotel-name {padding: 40px 60px;}
	.p-180 {padding-top: 120px; padding-bottom: 120px;}
	.core-value p{font-size: 18px;}
	.core-row .core-item {padding: 0 20px;}
}
@media(max-width : 1199px) {
	.p-180 {padding-top: 100px; padding-bottom: 100px;}
	.p-120 {
		padding-top: 75px;
    	padding-bottom: 75px;
	}
	.p-100 {
		padding-top: 65px;
    	padding-bottom: 65px;
	}
	.p-120.gray-bg {
		padding: 65px 0 90px;
	}
	.p-140 {
		padding-top: 90px;
		padding-bottom: 90px;
	}
	.about-sec {
		h3 {margin-bottom: 20px;}
		h5{margin-bottom: 25px}
		p{margin-bottom: 25px;}
	}
	.without-hero .header ,
	.header {
		padding: 45px 0;
	}
	.logo img {
	    width: 180px;
	    height: 58px;
	    object-fit: contain;
	}
	.container-fluid{
		padding-left: 20px;
		padding-right: 20px;	
	}
	.main-menu {font-size: 27px;}
	.hero-section {
		min-height: 420px;
		height: auto;
	}
	.home .hero-section {
	    height: auto;
	}
	.hotel-name {padding: 40px 30px}
	h5{font-size: 22px;}
	.news-row{margin-bottom: 60px;}
	.footer-row .f_items_sec:not(:nth-child(odd)) {padding-left: 0}
	.footer {font-size: 17px;}
	.result-sec-item:after ,
	.result-sec-item:before {display: none}
	.result-sec-item {
	    width: 260px;
	    height: 260px;
	    font-size: 44px;
	    margin-bottom: 30px;
	}
	.result-sec h3 {
		margin-bottom: 70px;
	}
	.news-full-sec {
		padding: 60px 0 80px;
	}
	.page-link {font-size: 24px;}
	.navi-share {padding-top: 90px;}
	.img-sec {height: 540px;}
	.about-data {
		padding: 30px 20px;
	}
	.map-contact .row {
		grid-row-gap: 25px;
		row-gap: 25px;
	}
	
	.map-sec iframe, .map-sec .iframe {
	    height: 500px;
	}
	.form-sec h4 {
	    margin-bottom: 60px;
	}
	.agent-sec h3{
	    margin-bottom: 60px;
	}
	.core-value h6 {font-size: 20px;}
	.core-row {
		margin:  0 -15px;
		.core-item {padding: 0 15px;}
	}
	.service_item {
	    padding: 0 15px;
	    h4{font-size: 30px;}
	}
	.services-list li {
		padding: 0 30px;
		font-size: 20px;
	}
	.featured-thumb-sec {margin-right: 0 !important;}
	.service-details {padding-bottom: 110px}
	.service_btm {padding-bottom: 75px;}
	.slider-nav .slick-arrow.slick-prev ,
	.slider-nav .slick-arrow.slick-next {display: none !important}
}
@media(max-width : 991px) {
	.hero-section {
		min-height: 320px;
	}
	.home .hero-section {
	    height: auto;
	}
	.logo img {
	    width: 158px;
	    height: 46px;
	}
	.main-menu {
		font-size: 23px;
		 ul {
		    grid-column-gap: 20px;
		    column-gap: 20px;
		}
	}

	.without-hero .header ,
	.header {padding: 30px 0}
	.without-hero .header.fixed,
	.header.fixed{padding: 15px 0}
	
	.result-sec-item {
		width: 195px;
    	height: 195px;
		font-size: 39px;
	}
	.result-sec-item .resultTxt:before  {
		height: 145px; width: 145px;
	}
	.result-sec p {font-size: 18px}
	.slick-arrow {
		left: 15px;
		width: 100px;
		height: 100px;
		top: -51px;
	}
	.slick-arrow.slick-next {
	    left: 135px;
	}
	.news-row {row-gap: 45px;}
	.thumb-item .thumb-img{
		width: 110px; height: 110px;
	}
	.thumb-item p{font-size: 16px;}
	.featured-left p{margin-bottom: 30px;}
	.p-120.gray-bg{padding: 65px 0}
	.featured-thumb-sec {
	    max-width: 65%;
	    width: 450px;
	}
	.featured-bottom{padding: 45px 20px;}
	.heading {
	    margin-bottom: 45px;
	}
	.date-data{margin-bottom: 15px;}
	.footer h6{margin-bottom: 25px;}
	.footer-row .f_items_sec:not(:nth-child(odd)) {
		    padding-left: calc(var(--bs-gutter-x) * 0.5);
	}
	.p-180 {padding-top: 90px; padding-bottom: 90px;}
	.p-140 {
		padding-top: 80px; padding-bottom: 80px;
	}
	.p-120 {
		padding-top: 60px; padding-bottom: 60px;
	}
	.result-sec h3 {
	    margin-bottom: 45px;
	}
	.footer-row {
	    grid-row-gap: 40px;
	    row-gap: 40px;
	}
	body {
	    font-size: 18px;
	}
	h5{font-size: 21px;}
	h3{font-size: 34px;}
	h6{font-size: 19px;}
	.hero-section:before {height: 100px;}
	.slider-nav .slick-arrow {display: none !important}
	.our-news h3 {margin-bottom: 35px;}
	.news-details h1 {font-size: 30px; margin-bottom: 35px;}
	.news-details p {
	    margin-bottom: 30px;
	}
	.news-details p img{padding: 15px 0}
	.navi-share {padding-top: 80px;}
	.img-sec {height: 400px;} 
	.about-data h5{margin-bottom: 5px;}
	.about-right {    padding-left: 12px}
	.about-right h6 {margin-bottom: 25px;}
	.story-slider {padding-top: 170px;}
	.happy-client h3 {top: 80px;}

	.contact-sec {
	    font-size: 18px;
	    line-height: 1.5;
	}
	.map-sec iframe {
	    height: 420px;
	}
	.core-row h6:after, .core-row h6:before {
		display: none;
	}
	.core-row {
	    grid-row-gap: 45px;
	    row-gap: 45px;
	}	
	.join-tem .d-flex {
		flex-wrap: wrap;
		h3{
			width: 100%;
			max-width: 100%;
			margin-bottom: 15px;
		}
		p{
			max-width: 80%;
			br{content: ''}
		}
	}
	.join-tem {
		padding: 45px 0;
	}
	.service-row{
		row-gap: 45px;
		grid-row-gap: 45px;
	}
	.service_item:nth-child(even){border: 0}
	.service_item h4 {
	    margin-bottom: 30px;
	}
	.services-list li {
	    padding: 0 20px;
	    font-size: 16px;
	}	
	.service-details{padding: 60px 0 80px;}
	.service-details h4 {
	    font-size: 30px;
	    margin-bottom: 25px;
	}
	.service_btm p {margin-top: 30px;}
}
@media(max-width : 767px) {
	.hero-section:before {
		height: 100px;
	}
	.hero-section {height: 260px; min-height: inherit;}
	.home .hero-section {height: 280px;
		min-height: inherit;
	}
	.main-menu{
		display: none;
	    position: absolute;
	    left: 0;
	    font-size: 21px;
	    top: 100%;
	    width: 100%;
	    background: rgb(7, 24, 59);
	    background-image: linear-gradient(-196deg, #07173a 0% , #0e2d74 100%);
		ul{
			padding: 10px 0;
			li {
				a {
					letter-spacing: .5px;
					line-height: 1;
					padding: 10px 15px;
					display: block;
				}
			}
		}
	}
	#nav-icon1 {
	  width: 36px;
	  height: 23px;
	  transform: rotate(0deg);
	  transition: 0.5s ease-in-out;
	  cursor: pointer;
	  padding: 0;
	  span {
	      display: block;
	      position: absolute;
	      height: 3px;
	      width: 100%;
	      background: #FBD901;
	      border-radius: 2px;
	      opacity: 1;
	      transform: rotate(0deg);
	      transition: 0.25s ease-in-out;
	      &:nth-child(1) {
	      top: 0px;
	  }
	  &:nth-child(2) {
	      top: 10px;
	  }
	  &:nth-child(3) {
	      top: 20px;
	  }
	  }
	  &.open{
	  	span{
	  		&:nth-child(1) {
	        top: 10px;
	        transform: rotate(135deg);
	    }
	    &:nth-child(3) {
	        top: 10px;
	        transform: rotate(-135deg);
	    }
	    &:nth-child(2){opacity: 0}
	  	}
	  }
	}


	body {
	    font-size: 17px;
	}
	.container-fluid, .news-sec .container-xl, .core-value .container, .agent-sec .container-xl {padding-left: 15px; padding-right: 15px;}
	h3{font-size: 30px;}
	h5{font-size: 19px;}
	h6{font-size: 17px;}
	.about-sec h5{padding: 0; margin-bottom:20px}
	.about-sec p {margin-bottom: 18px;}
	.without-hero .header,
	.header{
		padding: 20px 0;
	}
	.logo img {
    	width: 144px;
	    height: 42px;
	}
	.result-sec p {font-size: 16px; line-height: 1.25}
	.result-sec-item .resultTxt:before {
		height: 90px;
	    width: 90px;
	}	
	.result-sec-item {
	    width: 145px;
	    height: 145px;
	    font-size: 32px;
	    margin: 0 auto 20px;
	}
	.happy-client h3 {margin-bottom: 25px !important}
	.happy-client h6 {padding-top: 20px;}
	.hotel-name {
	    padding: 25px 15px;
	}
	.featured-bottom {flex-wrap: wrap;
		padding: 35px 15px;
	}
	.featured-left {
		width: 100%;
		max-width: 100%;
		text-align: center;
	}
	.featured-thumb-sec {
		padding-top: 30px;
		max-width: 100%;
		width: 100%;
	}
	.news-row > div {
	    padding: 0 10px;
	}
	.news-row {
	    margin: 0 -10px 60px;
	} 
	.p-140 {
		padding-top: 55px;
    	padding-bottom: 55px
	}
	.p-180 {
	    padding-top: 70px;
	    padding-bottom: 70px;
	}
	.footer {
		font-size: 15px;
		h6 {
			font-size: 16px;
		}	
	}
	.slider-nav .slick-track {
	    margin: 0 auto;
	} 
	.slick-arrow:before {
		display: none;
	}
	.slick-arrow {
		width: 68px;
		height: 68px;
		top: -35px;
	}
	.slick-arrow span.arrow {top: 1px;}
	.slick-arrow.slick-next {
	    left: 100px;
	}
	.featured-slider-section .slick-slide img {
		height: 320px;
	}
	.news-full-sec {
		padding: 55px 0 65px;
	}
	.our-news h3 {margin-bottom: 30px;}
	.page-link {font-size: 21px;}
	.news-details h1 {font-size: 27px; margin-bottom: 30px;}
	.news-details p {
	    margin-bottom: 25px;
	}
	.navi-share {padding: 0; padding-top: 60px;}
	.navi-share .social-icons ,
	.navi-share .pagination{font-size: 21px;}
	.about-left { margin-bottom: 40px;} 
	.img-sec {
		height: 480px;
	}
	.story-slider {
	    padding-top: 140px;
	}
	.about-right p {
	    margin-bottom: 20px;
	}

	.map-sec {
		border: 0;
		order: 1;
		border-bottom: 2px solid #FBD901;
	}
	.contact-sec {order: 2; 
		padding: 0 15px 25px 15px;
	}
	.h4-sty h4 ,
	.map-contact h4 {
		font-size:28px;
		margin-bottom: 25px;
	}

	.form-control {
		padding: 0 15px;
	}
	textarea.form-control:focus, textarea.form-control {
	    height: 160px;
	    padding: 10px 15px;
	}
	.p-100 {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.agent-left h5 {
		font-size: 18px; letter-spacing: 0;
	}
	.agent-sec h3, .agent-sec .h3 {
	    margin-bottom: 45px;
	}
	.agent-left p {font-size: 16px;}
	.service_item {border: 0;
		h4{margin-bottom: 25px;
			font-size: 27px;
		}
		p{
			font-size: 18px; margin-bottom: 30px;
			line-height: 1.5;
		}
	}
	.service-details h4 {font-size: 28px;
	    font-size: 28px;
	    letter-spacing: 1px;
	    margin-bottom: 20px;
	}
}
@media(max-width : 575px) {
	.p-180 {
	    padding-top: 60px;
	    padding-bottom: 60px;
	}
	.p-120.gray-bg ,
	.p-120 {
	    padding-top: 50px;
	    padding-bottom: 50px;
	}
	.p-100 {
		padding-top: 45px;
	    padding-bottom: 45px;
	}
	body {
	    font-size: 16px;
	}
	h3{font-size: 27px;}
	h5{font-size: 18px; letter-spacing: .6px;}
	h6{font-size: 16px;}
	.about-sec h5{padding: 0; margin-bottom:18px}
	.about-sec p {margin-bottom: 15px;}
	.result-sec-row{row-gap: 35px;
		li {
			width: 100%;
			max-width: 100%;
		}
	}
	.result-sec h3{
	    margin-bottom: 40px;
	}
	.featured-slider-section .slick-slide img {
	    height: 280px;
	}
	.date-data {line-height: 1}
	.news-sec h5,
	.line {
	    margin-bottom: 20px;
	}
	.news-row > div {text-align: center;}
	.link-btn{font-size: 18px;}
	.link-btn:befor{max-width: 80%;}
	.news-row {margin-bottom: 40px;}
	.f_items_sec .row{
		row-gap: 40px;
	}
	.footer {
		text-align: center;
		h6 {
		    margin-bottom: 20px;
		}
	}
	.social-icons ul ,
	.footer p {
		justify-content: center;
	}
	.footer {
		padding: 40px 0;
	}
	.hotel-name h5 {
		letter-spacing: .5px;
		font-size: 16px;
	}
	.news-full-sec {
		padding: 45px 0;
	}
	.our-news h3 {margin-bottom: 25px;}
	.filter-wrap:before {font-size: 22px;} 
	.filter-opt {font-size: 22px; width: 125px;}
	.news-details p img{padding: 10px 0}
	.navi-share {padding-top: 35px; row-gap: 15px;}
	.img-sec {
	    height: 360px;
	}
	.about-right h6 {margin-bottom: 20px;}
	.story-slider {
	    padding: 100px 0 90px;
	}
	.happy-client h3 {
	    top: 50px;
	}
	.about-data ul {
	    grid-gap: 15px;
	    gap: 15px;
	    font-size: 20px;
	}
	.contact-sec {text-align: center;}
	.map-sec iframe, .map-sec .iframe {
	    height: 359px;
	}
	.slick-slider .slick-dots {
		margin: 0 auto;
		right: 0;
		left: auto;
		width: 100%;
		bottom: 30px;
	}
	.join-tem {
		text-align: center;
		.d-flex p {
		    max-width: 100%;
		    width: 100%;
		    margin-bottom: 15px;
		}
	}
	.join-tem .link-btn:before, 
	.cont-link a:before {
		right: 0;
    	margin: 0 auto;
	}
	.join-tem .link-btn {
		display: block;
    	margin: 0 auto;
	}
	.col-agent-items-in{
		max-width: 420px;
		margin: 0 auto;
	}
	.service_item {
		h4{margin-bottom: 20px;
			font-size: 26px;
		}
		p{
			font-size: 16px; margin-bottom: 20px;
		}
	}
	.service-details{
		padding: 45px 0 75px;
	}
	.services-list{padding-top: 20px;}
	.services-list li + li ,
	.services-list li {
		width: 100%;
		border: 0;
	}
	.service_btm p {margin-top: 20px;}

	.service_btm {
	    padding-bottom: 55px;
	}
}
@media(max-width : 340px) {
	h3{font-size: 24px;}
	h5{font-size: 16px; letter-spacing: .5px;}
	h6 ,
	body{font-size: 14px;}
	.hero-section {height: 190px;}
	.home .hero-section {
		height: 210px;
	}
	.hotel-name h5 {
		letter-spacing: .4px;
		font-size: 14px;
	}
	.footer {
		padding: 35px 0;
	}
	.featured-slider-section .slick-slide img {
	    height: 220px;
	}
	.result-sec h3{
	    margin-bottom: 30px;
	}
	.footer-row ,
	.f_items_sec .row ,
	.result-sec-row{row-gap: 30px;}
	.p-120.gray-bg ,
	.p-120 {
	    padding-top: 40px;
	    padding-bottom: 40px;
	}
	.p-100 {
		padding-top: 35px;
	    padding-bottom: 35px;
	}
	.footer {font-size: 14px;
		h6{
			margin-bottom: 15px;
			line-height: 1.1;
		}
	}
	.footer p {margin: 0}
	.result-sec-item{font-size: 27px;}
	.result-sec p{font-size: 14px;}
	.result-sec-item .resultTxt:before{max-width: 80px; height: 80px;}
	.news-full-sec {
		padding: 40px 0;
	}
	.page-link {font-size: 20px;}
	.our-news h3 {margin-bottom: 20px;}
	.filter-wrap:before {font-size: 18px;}
	.filter-opt {font-size: 18px; width: 100px;}
	.main-menu {font-size: 18px;}
	.main-menu ul {
		padding: 7px 0;
		li a {padding: 7px 15px;}
	}
	.logo img {
	    width: 120px;
	    height: 35px;
	}
	.without-hero .header ,
	.header {padding: 15px 0}
	#nav-icon1 {width: 30px;}
	.news-details h1 {font-size: 24px; margin-bottom: 20px;}
	.news-details p {
	    margin-bottom: 18px;
	}
	.news-details p img{padding: 8px 0}
	.navi-share {padding-top: 30px; }
	.navi-share .social-icons ,
	.navi-share .pagination{font-size: 18px;}
	.about-right p {
	    margin-bottom: 15px;
	}
	.img-sec {
	    height: 300px;
	}
	.map-sec iframe, .map-sec .iframe {
	    height: 320px;
	}
	.join-tem{padding: 30px 0}
	.agent-sec h3, .agent-sec .h3 {
	    margin-bottom: 30px;
	}
	.service_btm {
	    padding-bottom: 40px;
	}
}

